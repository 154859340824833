import { Component } from "react";
import "./Select.scss";

export default class Select extends Component {
  render() {
    const { children, label, placeholder, error, errorMessage, ...etc } =
      this.props;
    let className = "select" + (error ? " select--error" : "");

    var inputElement;
    var placeholderElement;
    if (placeholder) {
      placeholderElement = <option value="0">{placeholder}</option>;
      inputElement = (
        <select defaultValue={{ label: placeholder, value: 0 }} {...etc}>
          {placeholderElement}
          {children}
        </select>
      );
    } else {
      inputElement = <select {...etc}>{children}</select>;
    }

    var errorElement;
    if (errorMessage) {
      errorElement = (
        <div className="textfield__error-message">{errorMessage}</div>
      );
    }

    return (
      <div className={className}>
        <label>{label}</label>
        <div className="select__arrow"></div>
        {inputElement}
        {errorElement}
      </div>
    );
  }
}
