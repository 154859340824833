import { Component } from "react";
import { Link } from "react-router-dom";
import Fnr from "images/fnr.svg";
import "./Footer.scss";

export default class Footer extends Component {
  render() {
    let className = "footer";

    return (
      <div className={className}>
        <div className="section">
          <div className="grid">
            <div className="col col24">
              <div className="grid">
                <div className="col col4 col-s-6 ">
                  <h3>PROMOCIONES</h3>
                  <ul>
                    <li>
                      <Link to="/oportunidades">Descuentos Web</Link>
                    </li>
                    <li>
                      <Link to="/oportunidades">Oportunidades</Link>
                    </li>
                  </ul>
                </div>
                <div className="col col4 col-s-6">
                  <h3>POSTVENTA</h3>
                  <ul>
                    <li>
                      <Link to="/postventa">Servicios</Link>
                    </li>
                    <li>
                      <a
                        href="https://territorioyacopini.com.ar/solicitar-turno/chevrolet"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Pedir turno de taller
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col col4 col-s-6">
                  <h3>PLANES DE AHORRO</h3>
                  <ul>
                    <li>
                      <Link to="/plan-chevrolet">Plan Chevrolet</Link>
                    </li>
                  </ul>
                </div>
                <div className="col col4 col-s-6 s19 s-m-1">
                  <h3>AYUDA</h3>
                  <p>
                    San Martin Sur 600. Godoy Cruz, Mendoza. Tel: +54 261
                    4222848
                  </p>
                  <ul>
                    <li>
                      <Link to="/">Facebook</Link>
                    </li>
                    <li>
                      <Link to="/">YouTube</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="grid">
            <div className="col col24">
              <div className="grid">
                <div className="col col24 footer__legal">
                  <div className="footer__fnr">
                    <div className="grid">
                      <div className="col col6 s19 col24m">
                        <img src={Fnr} alt="Find New Roads" />
                      </div>
                    </div>
                  </div>

                  <div className="section">
                    <h4>Texto legal de Yacopini Motors</h4>
                    <ol>
                      <li>
                        Fotos no contractuales. Las imágenes publicadas en este
                        sitio son de carácter ilustrativo.
                      </li>
                      <li>
                        Consulte equipamientos y especificaciones técnicas en
                        nuestro chat, o en nuestras sucursales: San Martin Sur
                        600, Godoy Cruz, Mendoza, tels: +54 261 4222848 / +54
                        261 4439300 - Avenida Mitre 721, San Rafael, Mendoza,
                        tels: +54 260 4424951 / +54 261 4193985
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
