import { currency } from "helpers/format";
import { Link } from "react-router-dom";
import "./CarButton.scss";

function CarButton({ vehicle, discountType = "Descuento Web" }) {
  let className = "car-button";

  let imageElement;
  if (vehicle.image) {
    imageElement = (
      <div className="car-button__image">
        <img src={vehicle.image} alt={vehicle.name} />
      </div>
    );
  }

  let discountElement;
  if (vehicle.discount > 0) {
    discountElement = (
      <div className="car-button__discount">
        {discountType} {currency(vehicle.discount)}
      </div>
    );
  }

  let buttonElement;

  buttonElement = (
    <Link to={vehicle.url} className={className}>
      {imageElement}
      <div className="car-button__name">
        <h3>{vehicle.name}</h3>
      </div>
      {/*
        <div className="car-button__price">
          <h3>Desde: {priceFrom}</h3>
        </div>
      */}
      {discountElement}

      <div className="car-button__hover" />
    </Link>
  );

  return <div>{buttonElement}</div>;
}

export default CarButton;
