import TopBar from "../organisms/topbar/index.jsx";
import Modal from "../organisms/modal/index.jsx";
import Footer from "../molecules/footer/index.jsx";
import Slider from "../molecules/slider/Heros.jsx";
import IconButton from "../atoms/iconbutton/index.jsx";
import BlockButton from "../atoms/blockbutton/index.jsx";
import OportunitiesIcon from "images/oportunidades-icon.svg";
import DiscountIcon from "images/webdiscount-icon.svg";
import PlansIcon from "images/plans-icon.svg";
import OnStarIcon from "images/onstar-icon.svg";
import OpportunitesImage from "images/home-ofertas.jpg";
import OnStarImage from "images/home-onstar.jpg";
import WebDiscountsImage from "images/home-descuentos.jpg";
import PlansImage from "images/home-planes.jpg";
import { useState } from "react";
import { useParameter } from "components/context/Context";
import { Helmet } from "react-helmet";

function Home() {
  const popups = useParameter("popups");
  const [showPopup, setShowPopup] = useState(
    !sessionStorage.getItem("popup_seen")
  );

  const popup =
    popups.length > 0
      ? popups[Math.round(Math.random() * (popups.length - 1))]
      : null;

  return (
    <>
      <Helmet>
        <title>Home - Yacopini Motors</title>
        <meta
          name="description"
          content={`Yacopini Motors - Concesionario oficial Chevrolet en Mendoza`}
        />
      </Helmet>

      {popup !== null && (
        <Modal
          open={showPopup}
          imageOnly
          link={popup.url}
          onClose={() => {
            setShowPopup(false);
            sessionStorage.setItem("popup_seen", "1");
          }}
        >
          {popup.images.length > 0 && (
            <img src={popup.images[0].path} alt={popup.title} />
          )}
          {popup.images.length === 0 && <div>{popup.title}</div>}
        </Modal>
      )}
      <TopBar />
      <Slider />
      <div className="section">
        <div className="grid">
          <div className="col col6 col-m-2">
            <IconButton icon={DiscountIcon} url="/oportunidades#descuentos">
              {" "}
              Descuentos Web{" "}
            </IconButton>
          </div>
          <div className="col col6 col-m-2">
            <IconButton icon={OportunitiesIcon} url="/oportunidades">
              {" "}
              Oportunidades{" "}
            </IconButton>
          </div>
          <div className="col col6 col-m-2">
            <IconButton icon={PlansIcon} url="/plan-chevrolet">
              {" "}
              Plan Chevrolet{" "}
            </IconButton>
          </div>
          <div className="col col6 col-m-2">
            <IconButton icon={OnStarIcon} url="/on-star">
              {" "}
              OnStar{" "}
            </IconButton>
          </div>
        </div>
      </div>

      <div className="section section--big">
        <div className="grid">
          <div className="col col6">
            <BlockButton
              url="/oportunidades"
              title="Descuento Web"
              image={WebDiscountsImage}
            >
              <p className="p">
                Si reservás tu 0km a través de nuestra web, tenés beneficios
                especiales. Buscá los autos marcados con descuento web y
                disfrutá de importantes descuentos en tu nuevo 0KM.
              </p>
            </BlockButton>
          </div>

          <div className="col col6">
            <BlockButton
              url="/oportunidades"
              title="Oportunidades"
              image={OpportunitesImage}
            >
              <p className="p">
                Todos los días publicamos descuentos especiales en determinados
                modelos. Visitanos diariamente y no te pierdas de adquirir tu
                0KM a un precio increible.
              </p>
            </BlockButton>
          </div>

          <div className="col col6">
            <BlockButton
              url="/plan-chevrolet"
              title="Planes de ahorro"
              image={PlansImage}
            >
              <p className="p">
                Plan Chevrolet te da la posibilidad de acceder, mediante el pago
                de cuotas mensuales en pesos (sujetas a variaciones de lista de
                precios), y de bajo monto, sin anticipos ni requisitos de
                ingreso.
              </p>
            </BlockButton>
          </div>

          <div className="col col6">
            <BlockButton url="/on-star" title="OnStar" image={OnStarImage}>
              <p className="p">
                OnStar ofrece asistencia 24 horas al día los 7 días de la semana
                sin importar donde estés. Disfrutá de esta nueva experiencia a
                bordo de tu Chevrolet. Empezá haciendo click en play y conocé
                todo lo que OnStar puede hacer por vos.
              </p>
            </BlockButton>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
