import Modal from "components/organisms/modal/index.jsx";
import BasicButton from "components/atoms/basicbutton/index.jsx";
import UnitCard from "components/atoms/unitCard/index.jsx";

function ModelPrices({ onClose, stock }) {
  return (
    <Modal title="Unidades disponibles" open onClose={onClose}>
      <div className="grid">
        <div className="col col24">
          {stock.map((unit) => (
            <UnitCard key={unit.id} vehicle={unit} />
          ))}

          {stock.length === 0 && (
            <div>
              No hay unidades disponibles de este modelo.
              <br />
              <br />
            </div>
          )}
        </div>
      </div>

      <div className="grid">
        <div className="col col24">
          <BasicButton onClick={onClose}>Cerrar</BasicButton>
        </div>
      </div>
    </Modal>
  );
}

export default ModelPrices;
