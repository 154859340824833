import { Component } from "react";
import { Link } from "react-router-dom";
import "./IconButton.scss";

export default class IconButton extends Component {
  render() {
    const { children, icon, url } = this.props;

    let className = "icon-button";

    var iconElement;
    if (icon) {
      iconElement = (
        <div className="icon-button__icon">
          <img src={icon} alt="" />
        </div>
      );
    }

    var buttonElement;
    if (url) {
      buttonElement = (
        <Link to={url} className={className}>
          {iconElement}
          <br />
          {children}
        </Link>
      );
    } else {
      buttonElement = (
        <div className={className}>
          {iconElement}
          <br />
          {children}
        </div>
      );
    }

    return <div className={className}>{buttonElement}</div>;
  }
}
