import ModelBar from "components/organisms/modelbar/index.jsx";
import Footer from "components/molecules/footer/index.jsx";
import Slider from "components/molecules/slider/FamilyHero.jsx";
import BasicButton from "components/atoms/basicbutton/index.jsx";
import PriceRequestModal from "components/organisms/model/PriceRequest";
import StockModal from "components/organisms/model/Stock";
import WaLogo from "images/wa-logo.png";
import { Link, Redirect, useParams } from "react-router-dom";
import {
  useContactMethod,
  useFamily,
  useOffers,
  useSubfamilies,
  getStock,
} from "components/context/Context";
import { useEffect, useState } from "react";
import { currency } from "helpers/format";
import { Helmet } from "react-helmet";

function Model({ tab = "landing" }) {
  const params = useParams();
  const family = useFamily(params.family);
  const whatsapps = useContactMethod("sales");
  const offers = useOffers(family);
  const subfamilies = useSubfamilies(family);
  const [priceRequested, priceWasRequested] = useState(
    !!sessionStorage.getItem("price_requested")
  );
  const [showPriceRequestModal, setShowPriceRequestModal] = useState(false);
  const [showSubfamilyStock, setShowSubfamilyStock] = useState(null);
  const [stock, setStock] = useState({});

  useEffect(() => {
    subfamilies.forEach((subfamily) => {
      getStock(family, subfamily).then((subfamilyStock) => {
        setStock((stock) => ({ ...stock, [subfamily.id]: subfamilyStock }));
      });
    });
  }, [family, subfamilies]);

  if (!family) {
    return <Redirect to="/" />;
  }

  function showPrices(family) {
    setShowSubfamilyStock(family);

    if (!priceRequested) {
      setShowPriceRequestModal(true);
      return;
    }
  }

  function onSuccessfulPriceRequest() {
    priceWasRequested(true);
    setShowPriceRequestModal(false);
    sessionStorage.setItem("price_requested", "true");
  }

  return (
    <>
      <Helmet>
        <title>{family.name} - Autos 0 KM - Yacopini Motors</title>
        <meta
          name="description"
          content={`Comprar ${family.name} 0 km online - Yacopini Motors`}
        />
      </Helmet>

      <Slider family={family} />

      <ModelBar family={family} active={tab === "landing" ? 1 : 2} />

      {showPriceRequestModal && (
        <PriceRequestModal
          onClose={() => setShowPriceRequestModal(false)}
          onSuccess={onSuccessfulPriceRequest}
          family={family}
        />
      )}

      {priceRequested && showSubfamilyStock && (
        <StockModal
          onClose={() => setShowSubfamilyStock(null)}
          stock={stock[showSubfamilyStock.id]}
        />
      )}

      {offers.length > 0 && tab === "landing" && (
        <div className="section section--big">
          <div className="grid">
            <div className="col col8 col-s-12 s-m-1">
              <h4>Oportunidades</h4>
              <h2>
                Hay {offers.length} {family.name} con descuento.
                <br /> Hasta{" "}
                {currency(
                  offers.reduce(
                    (best, offer) =>
                      offer.special_discount > best.special_discount
                        ? offer
                        : best,
                    offers[0]
                  ).special_discount
                )}{" "}
                OFF.
              </h2>

              <Link to={family.opportunities_url}>
                <BasicButton main>Ver oportunidades</BasicButton>
              </Link>

              {whatsapps.length > 0 && (
                <a
                  href={`https://wa.me/${whatsapps[0].whatsapp_number}?text=${family.name}`}
                >
                  <BasicButton icon={WaLogo}>Chatear con un asesor</BasicButton>
                </a>
              )}
            </div>
            <div className="col col12 s10 col-s-8 s-s-13 col24m">
              <img src={family.detail_image} className="image" alt="Cruze" />
            </div>
          </div>
        </div>
      )}

      {tab === "landing" && (
        <>
          <div className="section section--big section--gray">
            <div className="grid">
              <div className="col col12 col24m s1">
                <img
                  src={family.warranty_image}
                  className="image"
                  alt={`${family.name} garantía`}
                />
              </div>
              <div className="col col10 s13 s-m-1">
                <h4>Garantía</h4>
                <h2>
                  Todos los {family.name} tienen una garantía de{" "}
                  {family.warranty}
                </h2>
                <p className="p p-b">
                  Tener un {family.name} es garantía de tranquilidad de
                  seguridad y de que vas a tener un auto para disfrutar en cada
                  momento. Para realizar cualquier tipo de consulta relacionada
                  a la garantía de tu vehículo Chevrolet, acercate a tu
                  concesionario o llamá al +54 261 4439300
                </p>
                <a
                  href="https://www.youtube.com/watch?v=AT3bCfnHx7w"
                  target="_blak"
                  rel="noopener noreferrer"
                >
                  <BasicButton>Ver Video</BasicButton>
                </a>
              </div>
            </div>
          </div>

          <div className="section section--big">
            <div className="grid">
              <div className="col col8 ">
                <img
                  src={family.versions_image}
                  className="image"
                  alt="Cruze"
                />
              </div>
              <div className="col col16 s9 s-m-1">
                <h4>Precios y versiones</h4>
                <h2>
                  Conoce todas las versiones de {family.name} disponibles y
                  reservá online
                </h2>
                <Link to={family.stock_url}>
                  <BasicButton main>Ver Precios</BasicButton>
                </Link>

                {whatsapps.length > 0 && (
                  <a
                    href={`https://wa.me/${whatsapps[0].whatsapp_number}?text=${family.name}`}
                  >
                    <BasicButton icon={WaLogo}>
                      Chatear con un asesor
                    </BasicButton>
                  </a>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {tab === "prices" && (
        <div className="section section--big">
          <div className="grid">
            <div className="col col24">
              <h2>Comparar versiones</h2>
              {!priceRequested && (
                <BasicButton main onClick={() => showPrices()}>
                  Consultar Precios
                </BasicButton>
              )}
              {whatsapps.length > 0 && (
                <a
                  href={`https://wa.me/${whatsapps[0].whatsapp_number}?text=${family.name}`}
                >
                  <BasicButton icon={WaLogo}>Chatear con un asesor</BasicButton>
                </a>
              )}
              <br />
              <br />
              <div className="table-container">
                <table className="table characteristics-table">
                  <thead>
                    <tr>
                      {subfamilies.map((subfamily) => (
                        <th key={subfamily.id} style={{ whiteSpace: "nowrap" }}>
                          <div className="characteristics-model-image">
                            <img
                              width="100%"
                              height="auto"
                              src={family.image}
                              alt={`${family.name} ${subfamily.name}`}
                            />
                          </div>
                          <div className="characteristics-model-name">
                            {subfamily.name}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {subfamilies.map((subfamily) => (
                        <td key={subfamily.id}>
                          {priceRequested && stock[subfamily.id]?.length > 0 && (
                            <>
                              <strong>
                                {currency(stock[subfamily.id][0].getPrice())}
                              </strong>
                              <br />
                              <BasicButton
                                main
                                small
                                onClick={() => showPrices(subfamily)}
                              >
                                Ver disponibilidad
                              </BasicButton>
                            </>
                          )}
                          {!priceRequested && (
                            <BasicButton
                              main
                              small
                              onClick={() => showPrices(subfamily)}
                            >
                              Ver precios
                            </BasicButton>
                          )}
                          {priceRequested && stock[subfamily.id]?.length === 0 && (
                            <>
                              No hay unidades disponibles de este modelo.
                              <br />
                              <Link to="/contacto">
                                <BasicButton small>Contactanos</BasicButton>
                              </Link>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>

                    <tr>
                      {subfamilies.map((subfamily) => (
                        <td key={subfamily.id}>
                          {subfamily.features.join(" / ")}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

export default Model;
