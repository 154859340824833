import TopBar from "../organisms/topbar/index.jsx";
import Footer from "../molecules/footer/index.jsx";
import Slider from "../molecules/slider/Heros.jsx";
import PromotionCard from "../molecules/promotionCard/index.jsx";
import StockModal from "components/organisms/model/Stock";
import { useFamiliesWithOffers, useFamilies } from "components/context/Context";
import { useState } from "react";
import SliderImage1 from "images/generic-1.jpg";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

function Promotions() {
  const families = useFamilies();
  const familiesWithOffers = useFamiliesWithOffers();
  const params = useParams();
  const [showFamily, setShowFamily] = useState(
    families.find((family) => family.url_name === params.family)
  );
  const sliderItems = [SliderImage1];

  return (
    <>
      <Helmet>
        <title>
          Oportunidades {showFamily ? showFamily.name : ""} - Yacopini Motors
        </title>
        {showFamily ? (
          <meta
            name="description"
            content={`Oportunidades - ${showFamily.name} 0 km con precio especial - Yacopini Motors`}
          />
        ) : (
          <meta
            name="description"
            content={`Oportunidades de vehículos 0 km Chevrolet - Yacopini Motors`}
          />
        )}
      </Helmet>

      <TopBar />
      <Slider title="Oportunidades" items={sliderItems} />
      {showFamily && (
        <StockModal
          stock={showFamily.offers}
          onClose={() => setShowFamily(null)}
        />
      )}
      <div className="section section--big">
        <div className="grid">
          <div className="col col24">
            <h2>Oportunidades</h2>
            <p>
              Todos los días publicamos descuentos especiales en determinados
              modelos. Visitanos diariamente y no te pierdas de adquirir tu 0KM
              a un precio increible.{" "}
            </p>
          </div>
        </div>
        <div className="grid">
          {familiesWithOffers.length === 0 && (
            <div className="col col12">
              Volvé a visitarnos porque estamos preparando las mejores
              oportunidades para vos.
            </div>
          )}
          {familiesWithOffers.map((family) => (
            <div className="col col6 col-s-8" key={family.id}>
              <PromotionCard
                name={family.name}
                image={family.promotion_image}
                discount={family.best_offer?.special_discount}
                units={family.offers.length}
                onClick={() => setShowFamily(family)}
              />
            </div>
          ))}
        </div>
        <div className="grid">
          <div className="col col24">
            <p className="p p-smalltext">
              <small>
                * Vehículos únicos con condiciones especiales sujeto a la
                revisión y aprobación del cliente.
              </small>
            </p>
          </div>
        </div>
      </div>
      <div className="section section--big" id="descuentos">
        <div className="grid">
          <div className="col col24">
            <h2>Descuentos Web</h2>
            <p>
              Si reservás tu 0km a través de nuestra web, tenés beneficios
              especiales. Buscá los autos marcados con descuento web y disfrutá
              de importantes descuentos en tu nuevo 0KM.
            </p>
          </div>
        </div>
        <div className="grid">
          {families.map((family) =>
            !family.discount ? null : (
              <div className="col col6 col-s-8" key={family.id}>
                <PromotionCard
                  name={family.name}
                  image={family.promotion_image}
                  discount={family.discount}
                  url={family.url}
                  webDiscount
                />
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Promotions;
