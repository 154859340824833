function VehicleColor({ color }) {
  if (!color) {
    return null;
  }

  const style = {
    backgroundColor: color,
    height: ".5em",
    width: ".5em",
    borderRadius: ".25em",
    display: "inline-block",
  };

  return (
    <>
      <span style={style} /> <span style={style} /> <span style={style} />
    </>
  );
}

export default VehicleColor;
