import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "images/chevrolet-logo.png";
import MenuButton from "components/atoms/menubutton/index.jsx";
import CarButton from "components/atoms/carbutton/index.jsx";
import { useFamilies } from "components/context/Context";
import "./TopBarMobile.scss";

function TopBarMobile() {
  const families = useFamilies();
  const [state, setState] = useState({
    metaState: "1",
    menuOpen: false,
    scrolled: false,
    moreMenuOpen: false,
  });

  const toggleMenu = () => {
    setState({ ...state, menuOpen: !state.menuOpen });
  };
  const toggleMoreMenu = () => {
    setState({ ...state, moreMenuOpen: !state.moreMenuOpen });
  };

  useEffect(() => {
    function listenToScroll() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const winSize = window.innerHeight - 64;

      setState((state) => {
        return {
          ...state,
          scrolled: winScroll > 0,
          metaState: winScroll > winSize ? "1" : "2",
        };
      });
    }

    window.addEventListener("scroll", listenToScroll);

    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  let className =
    "top-bar-mobile" +
    (state.menuOpen ? " top-bar-state-open" : "") +
    (state.moreMenuOpen ? " top-bar-state-more-open" : "") +
    (state.scrolled ? " top-bar--scrolled" : "");

  return (
    <>
      <div className={className}>
        <div className="top-bar__main-menu">
          <div className="main-menu__logo">
            <Link to="/">
              <img src={Logo} alt="Yacopini Motors" />
            </Link>
          </div>

          <div className="main-menu__right-menu">
            <span onClick={toggleMenu}>
              <MenuButton arrow>Vehículos</MenuButton>
            </span>
            <MenuButton url="/oportunidades">Oportunidades</MenuButton>
            <MenuButton url="/plan-chevrolet">Planes</MenuButton>
            <span className="more-button" onClick={toggleMoreMenu}>
              <MenuButton arrow>Más</MenuButton>
            </span>
          </div>
        </div>
        <div className="top-bar__sections">
          <div className="section">
            <div className="grid">
              {families.map((family) => (
                <div
                  className="col col6 col-m-2"
                  key={family.id}
                  onClick={() => setState({ ...state, menuOpen: false })}
                >
                  <CarButton vehicle={family} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="top-bar__sections top-bar__sections-more">
          <div className="more-menu__item">
            <Link to="/postventa">Postventa</Link>
          </div>
          <div className="more-menu__item">
            <Link to="/accesorios">Accesorios</Link>
          </div>
          <div className="more-menu__item">
            <a
              rel="noopener noreferrer"
              href="https://territorioyacopini.com.ar/usados/"
              target="_blank"
            >
              Usados
            </a>
          </div>
          <div className="more-menu__item">
            <Link to="/contacto">Contacto</Link>
          </div>
          <div className="more-menu__item">
            <Link to="/turnos">Turnos Taller</Link>
          </div>
        </div>

        <div className="top-bar__fixed-bars"></div>
      </div>
    </>
  );
}

export default TopBarMobile;
