import { Component } from "react";
import BasicButton from "components/atoms/basicbutton/index.jsx";
import { currency } from "helpers/format";
import { Link } from "react-router-dom";

import "./PromotionCard.scss";

export default class PromotionCard extends Component {
  render() {
    const { name, discount, units, image, webDiscount, onClick, url } =
      this.props;

    let className =
      "promotion-card" + (webDiscount ? " promotion-card--web" : "");
    var unitsText;
    var buttonText;
    if (units > 1) {
      unitsText =
        "Hay " +
        units +
        " unidades disponibles con precio especial. Selecciona la unidad que te interese y reservá online en solo unos minutos.";
      if (webDiscount) {
        buttonText = "Ver más";
      } else {
        buttonText = "Ver Unidades";
      }
    } else {
      unitsText =
        "Hay 1 unidad disponible con precio especial. Reservá online en solo unos minutos.";

      if (webDiscount) {
        buttonText = "Ver más";
      } else {
        buttonText = "Reservar";
      }
    }

    var discountText;
    if (webDiscount) {
      discountText = (
        <div className="promotion-card__discount">
          <span>{currency(discount)}</span> de descuento web.
        </div>
      );
    } else {
      discountText = (
        <div className="promotion-card__discount">
          Hasta <span>{currency(discount)}</span> de descuento.
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="promotion-card__image">
          <img src={image} alt="" />
        </div>
        <div className="promotion-card__content">
          <div className="promotion-card__name">{name}</div>

          {discountText}
          <div className="promotion-card__actions">
            {onClick ? (
              <BasicButton main inverted onClick={onClick}>
                {buttonText}
              </BasicButton>
            ) : (
              <Link to={url}>
                <BasicButton main inverted>
                  {buttonText}
                </BasicButton>
              </Link>
            )}
          </div>
        </div>
        {!webDiscount && (
          <div className="promotion-card__info">
            <p>{unitsText}</p>
          </div>
        )}
      </div>
    );
  }
}
