import axios from "axios";
import BasicButton from "components/atoms/basicbutton/index.jsx";
import BenefitsTable from "components/organisms/buy/BenefitsTable";
import FormRow from "components/molecules/forms/formrow/index.jsx";
import Textfield from "components/molecules/forms/textfield/index.jsx";
import { useSavingsPlan } from "components/context/Context";
import { useState } from "react";

function PlanCouponValidator() {
  const [coupon, setCoupon] = useState(undefined);
  const [code, setCode] = useState("");
  const plan = useSavingsPlan(coupon?.plan);

  function handleSubmit(e) {
    e.preventDefault();

    setCoupon(undefined);

    axios
      .post("/api/gm/plans/validate", { code })
      .then((response) => setCoupon(response.data))
      .catch((e) => {
        console.error(e);
        setCoupon(null);
      });
  }

  if (coupon) {
    return (
      <>
        <h2>¿Tenés un código de beneficios?</h2>

        <p>¡Tu cupón es válido!</p>

        <BenefitsTable coupon={coupon} />

        <p>
          <br />
          <BasicButton main small url={plan.getCouponUrl(code)}>
            Subscribite al plan {plan.name}
          </BasicButton>
        </p>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <h2>¿Tenés un código de beneficios?</h2>

      <FormRow>
        <Textfield
          label="Validá tu código aquí:"
          placeholder="ABC-000"
          value={code}
          onChange={(e) => setCode(e.currentTarget.value)}
          error={coupon === null}
          errorMessage={
            coupon === null ? "El cupón es inválido o ha vencido" : undefined
          }
        />

        <BasicButton>Validar código</BasicButton>
      </FormRow>
    </form>
  );
}

export default PlanCouponValidator;
