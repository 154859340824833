import { Component } from "react";
import "./Modal.scss";

export default class Modal extends Component {
  render() {
    const { children, title, open, imageOnly, link, onClose } = this.props;

    let className =
      "modal" +
      (open ? " modal--open" : "") +
      (imageOnly ? " modal--image-only" : "");

    var linkElement;
    if (link && imageOnly) {
      linkElement = (
        <a
          href={link}
          className="modal__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
        </a>
      );
    }

    var modalElement;
    if (!imageOnly) {
      modalElement = (
        <div className="modal__body">
          <div className="modal__header">
            <h4>
              {" "}
              <strong>{title}</strong>
            </h4>
          </div>
          <div className="modal__content">{children}</div>
        </div>
      );
    } else {
      modalElement = (
        <div className="modal__body">
          <div className="modal__header">
            <div className="modal__header__close">
              <div className="modal__header__close__button"></div>
            </div>
          </div>
          {linkElement}
          <div className="modal__content">{children}</div>
        </div>
      );
    }

    return (
      <div className={className}>
        {modalElement}
        <div className="modal__close">
          <div className="modal__close__button" onClick={onClose} />
        </div>
        <div className="modal__overlay"></div>
      </div>
    );
  }
}
