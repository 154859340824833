import axios from "axios";
import TopBar from "../organisms/topbar/index.jsx";
import Footer from "../molecules/footer/index.jsx";
import BlockButton from "../atoms/blockbutton/index.jsx";
import BasicButton from "components/atoms/basicbutton/index.jsx";
import FormRow from "components/molecules/forms/formrow/index.jsx";
import Textfield from "components/molecules/forms/textfield/index.jsx";
import Select from "components/molecules/forms/select/index.jsx";
import Checkbox from "components/molecules/forms/checkbox/index.jsx";
import LinkingPos from "components/organisms/buy/LinkingPos";
import PriceTable from "components/organisms/buy/PriceTable";
import VehicleColor from "components/organisms/VehicleColor";
import {
  useFamily,
  useParameter,
  useStockItem,
} from "components/context/Context";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { scrollTo } from "helpers/scroll";
import { Helmet } from "react-helmet";
import preciosJustos from "images/precios-justos.png";

let couponTimeout;
function Buy({ withLoan = false }) {
  const params = useParams();
  const vehicle = useStockItem(params.id);
  const family = useFamily(vehicle?.family || "");
  const [request, setRequest] = useState({
    name: "",
    lastname: "",
    dni: "",
    birthdate: "",
    email: "",
    telephone: "",
    address: "",
    marital_status_id: "",
    accept_terms: false,
  });
  const [code, setCode] = useState("");
  const [coupon, setCoupon] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);
  const [requesting, isRequesting] = useState(false);
  const bookingPrice = useParameter("booking_price");
  const bookingAmount = bookingPrice || 200000;

  function handleChange(e) {
    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value });
  }

  function handleCheckboxChange(e) {
    setRequest({
      ...request,
      [e.currentTarget.name]: !request[e.currentTarget.name],
    });
  }

  function handleCouponChange(e) {
    const code = e.currentTarget.value;
    setCode(code);
    setCoupon(undefined);

    function checkCoupon(code) {
      axios
        .get(
          `/api/promotion?brand_id=2&chassis=${vehicle.chassis}&code=${code}&family=${vehicle.family}`
        )
        .then((response) => setCoupon(response.data.data))
        .catch((e) => {
          console.error(e);
          setCoupon(null);
        });
    }

    clearTimeout(couponTimeout);

    if (!code) {
      return;
    }

    couponTimeout = setTimeout(() => checkCoupon(code), 500);
  }

  function handleSubmit(e) {
    e.preventDefault();

    isRequesting(true);
    setTransaction(undefined);

    const data = {
      ...request,
      brand_id: 2,
      chasis: vehicle.chassis,
      color: vehicle.color,
      family: vehicle.family,
      family_discount: vehicle.discount,
      fullprice: vehicle.getPrice(coupon),
      model: vehicle.name,
      promotion_code: coupon ? coupon.code : "",
      special_discount: vehicle.special_discount,
      stock_id: vehicle.id,
    };

    axios
      .post("/api/gm/purchase", { data })
      .then((response) => {
        isRequesting(false);
        setTransaction(response.data.transaction || null);
      })
      .catch((e) => {
        console.error(e);
        isRequesting(false);
        setTransaction(null);
      });
  }

  function book() {
    scrollTo("overview");
  }

  function begin() {
    scrollTo("book-form");
  }

  function pay() {
    scrollTo("payment-form");
  }

  if (vehicle === undefined) {
    return Loading();
  }

  if (vehicle === null) {
    return NoLongerAvailable();
  }

  const missingData =
    !request.name ||
    !request.lastname ||
    !request.dni ||
    !request.birthdate ||
    !request.email ||
    !request.marital_status_id ||
    !request.accept_terms ||
    !request.telephone;

  return (
    <>
      <Helmet>
        <title>Reservar {family.name} 0 km - Yacopini Motors</title>
        <meta
          name="description"
          content={`Reservar ${family.name} 0 km online - Yacopini Motors`}
        />
      </Helmet>

      <TopBar />

      <div className="section section--big">
        <div className="grid">
          <div className="col col12 col24m">
            <h4>Estas reservando online:</h4>
            <h1>{family.name}</h1>
            <p className="p p-b">
              <strong> Versión:</strong> {vehicle.name} <br />
              <strong> Color: </strong> {vehicle.color}{" "}
              <VehicleColor color={vehicle.hex_color} />
              <br />
              <strong>Número de Chasis:</strong> {vehicle.chassis} <br />
              {vehicle.special_discount > 0 && (
                <span className="p p-s">
                  Vehiculo único con condiciones especiales sujeto a la revisión
                  y aprobación del cliente.
                </span>
              )}
            </p>

            <BasicButton main onClick={book}>
              Reservar
            </BasicButton>
          </div>
          <div className="col col12 col24m">
            <img src={family.detail_image} alt="S10" />
          </div>
        </div>
      </div>
      <div className="section section--gray">
        <div className="grid">
          <div className="col col8">
            <BlockButton title="1. ELEGÍ CÓMO PAGAR TU 0KM">
              <p className="p">Revisá el resúmen de la operación.</p>
            </BlockButton>
          </div>
          <div className="col col8">
            <BlockButton title="2. COMPLETA TUS DATOS">
              <p className="p">Completá tus datos personales.</p>
            </BlockButton>
          </div>
          <div className="col col8">
            <BlockButton title="3. RESERVÁ TU COMPRA">
              <p className="p">
                Son solo $5.000 que podés pagar con tu tarjeta de débito, o
                crédito en 1, 2 o 3 cuotas.
              </p>
            </BlockButton>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="section section--big" id="overview">
        <div className="grid">
          <div className="col col10 s8 col-s-14 s-s-6 s-m-1">
            <h2>Resumen de la reserva</h2>

            {vehicle.govPrice && (
              <img
                src={preciosJustos}
                alt="Precios Justos"
                width="auto"
                height="100"
                style={{ marginBottom: ".5em" }}
              />
            )}

            <PriceTable vehicle={vehicle} coupon={coupon}>
              {!coupon && (
                <tr>
                  <td>
                    ¿Tenés un cupón de descuento? <br />
                    Ingresa tu código aquí.
                  </td>
                  <td className="price">
                    <Textfield
                      placeholder="00-0000"
                      value={code}
                      onChange={handleCouponChange}
                      error={coupon === null}
                      errorMessage={
                        coupon === null
                          ? "Cupón inexistente o vencido"
                          : undefined
                      }
                    />
                  </td>
                </tr>
              )}
            </PriceTable>
            {/*
            <BasicButton main>Comprar Contado</BasicButton>
            <BasicButton>Financiar</BasicButton>*/}
            <br />
            <br />
            {!transaction && (
              <BasicButton onClick={begin}>Comenzar</BasicButton>
            )}
            {transaction && (
              <BasicButton onClick={pay}>Pagar reserva</BasicButton>
            )}
          </div>
        </div>
      </div>

      {!transaction && (
        <form onSubmit={handleSubmit} id="book-form">
          <div className="section section--big">
            <div className="grid">
              <div className="col col10 s8 col-s-14 s-s-6 s-m-1">
                <h2>Completá tus datos personales</h2>
                <br />
                <FormRow>
                  <div className="grid">
                    <div className="col col12 col24m">
                      <Textfield
                        label="Nombre:"
                        name="name"
                        value={request.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col col12 col24m">
                      <Textfield
                        label="Apellido:"
                        name="lastname"
                        value={request.lastname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col10">
                      <Textfield
                        label="DNI:"
                        name="dni"
                        value={request.dni}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col col14">
                      <Textfield
                        label="Fecha de nacimiento:"
                        placeholder="DD/MM/AAAA"
                        type="date"
                        name="birthdate"
                        value={request.birthdate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col10">
                      <Select
                        label="Estado civil"
                        name="marital_status_id"
                        value={request.marital_status_id}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Seleccionar estado civil</option>
                        <option value="2">Casado</option>
                        <option value="3">Divorciado</option>
                        <option value="1">Soltero</option>
                        <option value="4">Viudo</option>
                      </Select>
                    </div>
                  </div>
                </FormRow>
                <hr />
                <FormRow>
                  <div className="grid">
                    <div className="col col20">
                      <Textfield
                        label="Dirección:"
                        name="address"
                        value={request.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col12 col24m">
                      <Textfield
                        label="Email:"
                        type="email"
                        name="email"
                        value={request.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col12 col24m">
                      <Textfield
                        label="Teléfono:"
                        name="telephone"
                        value={request.telephone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <br />
                <br />
                <BasicButton>Continuar</BasicButton>
              </div>
            </div>
          </div>

          <div className="section section--big">
            <div className="grid">
              <div className="col col10 s8 col-s-14 s-s-6 s-m-1">
                <h2>Confirma tu reserva</h2>
                <p>
                  {request.name} estas a punto de reservar online este{" "}
                  {vehicle.name}
                  0KM.
                </p>
                <p>
                  Recordá que una vez que realices el pago de la seña tenes 48
                  horas para acercarte nuestro concesionario y completar la
                  operación. Si no estas conforme con la compra, se te
                  reintegrará el importe completo de la seña.
                </p>
                <br />

                <PriceTable vehicle={vehicle} coupon={coupon} />

                <br />
                <FormRow>
                  <div className="grid">
                    <div className="col col20">
                      <Checkbox
                        name="accept_terms"
                        value={true}
                        onChange={handleCheckboxChange}
                        checked={request.accept_terms}
                        text="Acepto los términos y condiciones"
                      />
                    </div>
                  </div>
                </FormRow>
                <br />
                <br />

                {!requesting && (
                  <BasicButton main type="submit" disabled={missingData}>
                    Confirmar reserva
                  </BasicButton>
                )}
                {requesting && <div>Un segundo...</div>}
                {transaction === null && (
                  <div>
                    <br />
                    <p>
                      <strong>No pudimos completar tu solicitud.</strong>
                    </p>
                    <p>
                      Por favor intenta nuevamente o{" "}
                      <a href="/contacto">contactanos</a>.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      )}

      {transaction && (
        <div className="section section--big">
          <div className="grid">
            <div
              className="col col10 s8 col-s-14 s-s-6 s-m-1"
              id="payment-form"
            >
              <h2>Pagá tu reserva</h2>
              <LinkingPos amount={bookingAmount} transaction={transaction} />
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

function NoLongerAvailable() {
  return (
    <>
      <TopBar />

      <div className="section section--big">
        <div className="grid">
          <div className="col col12">
            <h4>Este vehículo ya no se encuentra disponible.</h4>
          </div>
        </div>
      </div>
    </>
  );
}

function Loading() {
  return (
    <>
      <TopBar />

      <div className="section section--big">
        <div className="grid">
          <div className="col col12">
            <h4>Consultando stock...</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Buy;
