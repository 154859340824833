import { Component } from "react";
import { Link } from "react-router-dom";
import "./MenuButton.scss";

export default class MenuButton extends Component {
  render() {
    const { children, arrow, url, active, external } = this.props;

    let className =
      "menu-button" +
      (arrow ? " menu-button--arrow" : "") +
      (active ? " menu-button--active" : "");

    var arrowElement;
    if (arrow) {
      arrowElement = <span className="menu-button__arrow"> </span>;
    }

    var buttonElement;
    if (url && !external) {
      buttonElement = (
        <Link to={url} className={className}>
          {children} {arrowElement}
        </Link>
      );
    } else if (url && external) {
      buttonElement = (
        <a href={url} className={className}>
          {children} {arrowElement}
        </a>
      );
    } else {
      buttonElement = (
        <div className={className}>
          {children} {arrowElement}
        </div>
      );
    }

    return <div className={className}>{buttonElement}</div>;
  }
}
