import {
  getImage,
  getDetailImage,
  getVersionsImage,
  getWarrantyImage,
  getPromotionImage,
} from "helpers/image";
import { url } from "helpers/format";

const COLOR_MAP = {
  "DARKMOON BLUE": "#3b5478",
  "DARKMOON BLUE MET-5": "#3b5478",
  "SATIN STEEL": "#81898f",
  "SATIN STEEL 5": "#81898f",
  "ABALONE WHITE": "#dcdcda",
  "SUMMIT WHITE": "#f3f3f3",
  "SWITCHBLADE SILVER": "#aeb3b7",
  "SWITHCHBLADE SILVER": "#aeb3b7",
  "BLACK MEET KETTLE": "#12150c",
  "RED-E OR NOT": "#af0f29",
  "GLORY RED": "#b0112b",
  "GLOBAL SON OF A GUN": "#262626",
  "CHILLI RED": "#8c141f",
  "OLD BLUE EYES": "#3b5478",
  "SATIN STEEL GREY": "#81898f",
  "DARK SHADOW": "#11112c",
  "SON OF A GUN": "#000000",
  "BLUE SEEKER": "#3b5478",
  "SWITCHBLADE SILVER MET": "#aeb3b7",
  "BLACK MEET KETTLE MET": "#12150d",
  "EDIBLE BERRIES": "#4d0920",
  "ABALONE WHITE 5": "#F5F3F1",
};

const specialPricePeriod = new Date().toISOString() <= "2023-10-31";

export default class StockVehicle {
  constructor({
    price_special,
    fullprice,
    price,
    fullprice_special,
    list_price,
    special_discount,
    family_id,
    chassis,
    chasis,
    discount,
    ...props
  }) {
    for (let key in props) {
      if (props.hasOwnProperty(key)) {
        this[key] = props[key];
      }
    }

    this.chassis = chassis || chasis;

    if (fullprice) {
      this.fullprice =
        fullprice_special > 0
          ? parseInt(fullprice_special, 10)
          : parseInt(fullprice, 10);
      this.list_price = parseInt(list_price, 10);
      this.discount = parseInt(discount, 10);
    } else if (price) {
      this.fullprice = parseInt(price, 10);
      this.list_price = 0;
      this.discount = 0;
    }

    this.special_discount = parseInt(special_discount, 10);
    this.family_id = parseInt(family_id, 10);

    if (
      specialPricePeriod &&
      props.name &&
      props.name.toLowerCase().includes("MONTANA") &&
      props.name.toLowerCase().includes("LTZ")
    ) {
      this.govPrice = true;
    }
  }

  get hex_color() {
    if (!this.color) {
      return "";
    }

    const color = this.color.toUpperCase();

    return COLOR_MAP[color];
  }

  get book_url() {
    return `/vehiculos-0km/reservar-online/${this.id}`;
  }

  get finance_url() {
    return `/vehiculos-0km/reservar-online-financiado/${this.id}`;
  }

  get thumbnail() {
    return getImage(this);
  }

  get image() {
    return getDetailImage(this);
  }

  get versions_image() {
    return getVersionsImage(this);
  }

  get warranty_image() {
    return getWarrantyImage(this);
  }

  get promotion_image() {
    return getPromotionImage(this);
  }

  get family_url_name() {
    return url(this.family);
  }

  getDiscountFromCoupon(promotion) {
    const price = this.getPrice();

    if (promotion.money_discount) {
      return promotion.money_discount;
    }

    return (promotion.percentage_discount * price) / 100;
  }

  getPrice(promotion = null) {
    return promotion
      ? this.fullprice - this.getDiscountFromCoupon(promotion)
      : this.fullprice;
  }
}
