import TimeLeft from "./TimeLeft";
import "./BenefitsTable.scss";
import BasicButton from "components/atoms/basicbutton/index.jsx";

function PriceTable({ children, coupon }) {
  return (
    <div className="BenefitsTable">
      <table className="table buy-table">
        {coupon && (
          <thead>
            <tr>
              <th>Cupón de beneficios</th>
              <th className="price">{coupon.name}</th>
            </tr>
          </thead>
        )}
        <tbody>
          {coupon &&
            coupon.benefits.map((benefit, i) => (
              <tr key={i}>
                <td colSpan={2}>{benefit}</td>
              </tr>
            ))}

          {children}
        </tbody>
      </table>
      <div className="coupon-info grid">
        {coupon && (
          <div className="seller col col16">
            <h6>Vendedor</h6>
            <h2>{coupon.salesman.fullname}</h2>

            {coupon.salesman.whatsapp_number && (
              <div className="seller__whatsapp">
                {" "}
                <BasicButton
                  external
                  main
                  small
                  url={`https://wa.me/${coupon.salesman.whatsapp_number}`}
                >
                  Contactar por WhatsApp
                </BasicButton>
              </div>
            )}

            {coupon.salesman.zoom_link && (
              <div className="seller__zoom">
                {" "}
                <BasicButton
                  small
                  main
                  external
                  url={coupon.salesman.zoom_link}
                >
                  Contactar por videollamada
                </BasicButton>{" "}
              </div>
            )}
          </div>
        )}
        {coupon && (
          <div className="expiration col col8">
            <h6>Vencimiento</h6>
            <TimeLeft coupon={coupon} />
          </div>
        )}
      </div>
    </div>
  );
}

export default PriceTable;
