import { Link } from "react-router-dom";
import { useContactMethod } from "components/context/Context";
import "./ModelBar.scss";

function ModelBar({ active, family }) {
  const whatsapps = useContactMethod("sales");

  return (
    <>
      <div className="model-bar" id="conocelo">
        <div className="grid">
          <div className="col col6 col-s-12">
            <div className="model-bar__model-name">
              <h2>{family.name}</h2>
            </div>
          </div>
          <div className="col col18 col-s-12">
            <div className="model-bar__menu">
              <Link
                to={family.url}
                className={
                  active === 1 ? "menu__link menu__link--active " : "menu__link"
                }
              >
                Caracteristicas
              </Link>
              <Link
                to={family.stock_url}
                className={
                  active === 2 ? "menu__link menu__link--active " : "menu__link"
                }
              >
                Precios y versiones
              </Link>

              {whatsapps.length > 0 && (
                <a
                  href={`https://wa.me/${whatsapps[0].whatsapp_number}?text=${family.name}`}
                  className="menu__button"
                >
                  Chatear con un asesor
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModelBar;
