import CruzeImage from "images/models/cruze.jpg";
import Cruze5Image from "images/models/cruze-5.jpg";
import EquinoxImage from "images/models/equinox.png";
import MontanaImage from "images/models/montana.webp";
import TrackerImage from "images/models/tracker.jpg";
import OnixImage from "images/models/onix.png";
import OnixPlusImage from "images/models/onix-plus.jpg";
import JoyImage from "images/models/joy.jpg";
import JoyPlusImage from "images/models/joy-plus.jpg";
import S10Image from "images/models/s10.jpg";
import SpinImage from "images/models/spin.jpg";
import TrailblazerImage from "images/models/trailblazer.jpg";

import CruzePImage from "images/models/promotions/cruze.jpg";
import Cruze5PImage from "images/models/promotions/cruze-5.jpg";
import EquinoxPImage from "images/models/promotions/equinox.jpg";
import MontanaPImage from "images/models/promotions/montana.jpg";
import TrackerPImage from "images/models/promotions/tracker.jpg";
import OnixPImage from "images/models/promotions/onix.jpg";
import OnixPlusPImage from "images/models/promotions/onix-plus.jpg";
import JoyPImage from "images/models/promotions/joy.webp";
import JoyPlusPImage from "images/models/promotions/joy-plus.jpg";
import S10PImage from "images/models/promotions/s10.jpg";
import SpinPImage from "images/models/promotions/spin.jpg";
import TrailblazerPImage from "images/models/promotions/trailblazer.jpg";

import CruzeImageDetail from "images/models/detail/cruze.jpg";
import CruzeImageVersions from "images/models/detail/cruze-versions.jpg";
import CruzeImageWarranty from "images/models/detail/cruze-warranty.jpg";
import Cruze5ImageDetail from "images/models/detail/cruze-5.jpg";
import Cruze5ImageVersions from "images/models/detail/cruze-5-versions.jpg";
import Cruze5ImageWarranty from "images/models/detail/cruze-5-warranty.jpg";
import EquinoxImageDetail from "images/models/detail/equinox.jpg";
import EquinoxImageVersions from "images/models/detail/equinox-versions.jpg";
import EquinoxImageWarranty from "images/models/detail/equinox-warranty.jpg";
import MontanaImageDetail from "images/models/detail/montana.jpg";
import MontanaImageVersions from "images/models/detail/montana-versions.jpg";
import MontanaImageWarranty from "images/models/detail/montana-warranty.jpg";
import TrackerImageDetail from "images/models/detail/tracker.jpg";
import TrackerImageVersions from "images/models/detail/tracker-versions.jpg";
import TrackerImageWarranty from "images/models/detail/tracker-warranty.jpg";
import OnixImageDetail from "images/models/detail/onix.jpg";
import OnixImageVersions from "images/models/detail/onix-versions.jpg";
import OnixImageWarranty from "images/models/detail/onix-warranty.jpg";
import OnixPlusImageDetail from "images/models/detail/onix-plus.jpg";
import OnixPlusImageVersions from "images/models/detail/onix-plus-versions.jpg";
import OnixPlusImageWarranty from "images/models/detail/onix-plus-warranty.jpg";
import JoyPlusImageWarranty from "images/models/detail/joy-plus-warranty.webp";

import S10ImageDetail from "images/models/detail/s10.jpg";
import S10ImageVersions from "images/models/detail/s10-versions.jpg";
import S10ImageWarranty from "images/models/detail/s10-warranty.jpg";
import SpinImageDetail from "images/models/detail/spin.jpg";
import SpinImageVersions from "images/models/detail/spin-versions.jpg";
import SpinImageWarranty from "images/models/detail/spin-warranty.jpg";
import TrailblazerImageDetail from "images/models/detail/trailblazer.jpg";
import TrailblazerImageVersions from "images/models/detail/trailblazer-versions.jpg";
import TrailblazerImageWarranty from "images/models/detail/trailblazer-warranty.jpg";
import JoyImageDetail from "images/models/detail/joy.webp";
import JoyImageVersions from "images/models/detail/joy-versions.webp";
import JoyImageWarranty from "images/models/detail/joy-warranty.webp";

export function getImage(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "cruze":
    case "nuevo cruze":
      return CruzeImage;
    case "cruze 5":
    case "cruze-5":
      return Cruze5Image;
    case "equinox":
      return EquinoxImage;
    case "montana":
      return MontanaImage;
    case "tracker":
    case "nueva tracker":
      return TrackerImage;
    case "onix joy":
    case "joy plus":
      return JoyPlusImage;
    case "joy":
      return JoyImage;
    case "nuevo onix":
    case "onix":
      return OnixImage;
    case "nuevo onix plus":
    case "onix plus":
      return OnixPlusImage;
    case "s10":
    case "s10 cabina doble":
      return S10Image;
    case "spin":
    case "spin activ":
    case "spin active":
      return SpinImage;
    case "trailblazer":
      return TrailblazerImage;
    default:
      console.log(`Image for ${name} not found.`);

      return "";
  }
}

export function getPromotionImage(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "cruze":
    case "nuevo cruze":
      return CruzePImage;
    case "cruze 5":
    case "cruze-5":
      return Cruze5PImage;
    case "equinox":
      return EquinoxPImage;
    case "montana":
      return MontanaPImage;
    case "tracker":
    case "nueva tracker":
      return TrackerPImage;
    case "onix plus":
    case "nuevo onix plus":
      return OnixPlusPImage;
    case "nuevo onix":
    case "onix":
      return OnixPImage;
    case "joy":
    case "onix joy":
      return JoyPImage;
    case "joy plus":
      return JoyPlusPImage;
    case "s10":
    case "s10 cabina doble":
      return S10PImage;
    case "spin":
    case "spin activ":
    case "spin active":
      return SpinPImage;
    case "trailblazer":
      return TrailblazerPImage;
    default:
      console.log(`Image for ${name} not found.`);

      return "";
  }
}

export function getDetailImage(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "cruze":
    case "nuevo cruze":
      return CruzeImageDetail;
    case "cruze 5":
    case "nuevo cruze 5":
      return Cruze5ImageDetail;
    case "equinox":
      return EquinoxImageDetail;
    case "montana":
      return MontanaImageDetail;
    case "tracker":
    case "nueva tracker":
      return TrackerImageDetail;
    case "joy":
    case "onix joy":
      return JoyImageDetail;
    case "nuevo onix plus":
      return OnixPlusImageDetail;
    case "nuevo onix":
    case "onix":
      return OnixImageDetail;
    case "onix plus":
      return OnixPlusImageDetail;
    case "joy plus":
      return JoyPlusImage;
    case "s10":
    case "s10 cabina doble":
      return S10ImageDetail;
    case "spin":
    case "spin activ":
    case "spin active":
      return SpinImageDetail;
    case "trailblazer":
      return TrailblazerImageDetail;
    default:
      console.log(`Image for ${name} not found.`);

      return "";
  }
}

export function getVersionsImage(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "cruze":
    case "nuevo cruze":
      return CruzeImageVersions;
    case "cruze 5":
    case "nuevo cruze 5":
      return Cruze5ImageVersions;
    case "equinox":
      return EquinoxImageVersions;
    case "montana":
      return MontanaImageVersions;
    case "tracker":
    case "nueva tracker":
      return TrackerImageVersions;
    case "onix plus":
    case "nuevo onix plus":
      return OnixPlusImageVersions;
    case "nuevo onix":
    case "onix":
      return OnixImageVersions;
    case "joy":
    case "onix joy":
      return JoyImageVersions;
    case "joy plus":
      return JoyPlusImage;
    case "s10":
    case "s10 cabina doble":
      return S10ImageVersions;
    case "spin":
    case "spin activ":
    case "spin active":
      return SpinImageVersions;
    case "trailblazer":
      return TrailblazerImageVersions;
    default:
      console.log(`Image for ${name} not found.`);

      return "";
  }
}

export function getWarrantyImage(vehicle) {
  const name = vehicle.family ?? vehicle.name;

  switch (name.toLowerCase()) {
    case "cruze":
    case "nuevo cruze":
      return CruzeImageWarranty;
    case "cruze 5":
    case "nuevo cruze 5":
      return Cruze5ImageWarranty;
    case "equinox":
      return EquinoxImageWarranty;
    case "montana":
      return MontanaImageWarranty;
    case "tracker":
    case "nueva tracker":
      return TrackerImageWarranty;
    case "nuevo onix plus":
    case "onix plus":
      return OnixPlusImageWarranty;
    case "nuevo onix":
    case "onix":
      return OnixImageWarranty;
    case "joy":
    case "onix joy":
      return JoyImageWarranty;
    case "joy plus":
      return JoyPlusImageWarranty;
    case "s10":
    case "s10 cabina doble":
      return S10ImageWarranty;
    case "spin":
    case "spin activ":
    case "spin active":
      return SpinImageWarranty;
    case "trailblazer":
      return TrailblazerImageWarranty;
    default:
      console.log(`Image for ${name} not found.`);

      return "";
  }
}
