import "./App.scss";
import Home from "./components/pages/home";
import Model from "./components/pages/model/model";
import Promotions from "./components/pages/promotions";
import Plans from "./components/pages/plans";
//import Financing from "./components/pages/financing";
import Buy from "./components/pages/buy";
import Subscribe from "./components/pages/subscribe";
import Context from "components/context/Context";
import TopBar from "components/organisms/topbar/index.jsx";
import TopBarMobile from "components/organisms/topbarMobile/index.jsx";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const Services = React.lazy(() => import("./components/pages/services"));
const Accesories = React.lazy(() => import("./components/pages/accesories"));
const Contact = React.lazy(() => import("./components/pages/contact"));
const OnStar = React.lazy(() => import("./components/pages/onstar"));

function App() {
  return (
    <Context>
      <Router>
        <div className="App">
          <TopBar />
          <TopBarMobile />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/vehiculos-0km/:family" exact>
              <Model tab="landing" />
            </Route>
            <Route path="/vehiculos-0km/:family/precios" exact>
              <Model tab="prices" />
            </Route>
            <Route path="/vehiculos-0km/reservar-online/:id" exact>
              <Buy />
            </Route>
            {/*<Route path="/vehiculos-0km/reservar-online-financiado/:id" exact>
              <Buy withLoan />
            </Route>*/}
            <Route path="/turno-cancelado/:code" exact>
              <div className="section section--big">
                <div className="grid">
                  <div className="col col12 col24m">
                    <h2>Postventa</h2>
                    <p>
                      La asistencia al turno de taller ha sido cancelada. No
                      dudes en volver a solicitar un turno a través de{" "}
                      <a href="https://territorioyacopini.com.ar/solicitar-turno/chevrolet">
                        nuestro sistema
                      </a>
                      .
                    </p>
                    <p>
                      <a href="/">Continuar</a>
                    </p>
                  </div>
                </div>
              </div>
            </Route>
            <Route path="/oportunidades" exact>
              <Promotions />
            </Route>
            <Route path="/oportunidades/:family" exact>
              <Promotions />
            </Route>
            <Route path="/postventa" exact>
              <Suspense fallback={<div>Un segundo...</div>}>
                <Services />
              </Suspense>
            </Route>
            <Route path="/plan-chevrolet" exact>
              <Plans />
            </Route>
            <Route path="/plan-chevrolet/subscripcion/:id/:name" exact>
              <Subscribe />
            </Route>
            <Route path="/plan-chevrolet/subscripcion/:id/:name/:coupon" exact>
              <Subscribe />
            </Route>
            {/*<Route path="/financiacion" exact>
              <Financing />
            </Route>*/}
            <Route path="/accesorios" exact>
              <Suspense fallback={<div>Un segundo...</div>}>
                <Accesories />
              </Suspense>
            </Route>
            <Route path="/contacto" exact>
              <Suspense fallback={<div>Un segundo...</div>}>
                <Contact />
              </Suspense>
            </Route>
            <Route path="/on-star" exact>
              <Suspense fallback={<div>Un segundo...</div>}>
                <OnStar />
              </Suspense>
            </Route>
          </Switch>
        </div>
      </Router>
    </Context>
  );
}

export default App;
