import { Component } from "react";
import { Link } from "react-router-dom";
import "./BasicButton.scss";

export default class BasicButton extends Component {
  render() {
    const {
      children,
      icon,
      url,
      main,
      small,
      external,
      inverted,
      onClick,
      ...etc
    } = this.props;

    let className =
      "basic-button" +
      (main ? " basic-button--main" : "") +
      (small ? " basic-button--small" : "") +
      (inverted ? " basic-button--inverted" : "") +
      (icon ? " basic-button--icon" : "");

    var iconElement;
    if (icon) {
      iconElement = (
        <div className="basic-button__icon">
          <img src={icon} alt="" />
        </div>
      );
    }

    if (url) {
      if (external) {
        return (
          <a
            href={url}
            className={className}
            target="_blank"
            rel="noopener noreferrer"
          >
            {iconElement}
            {children}
          </a>
        );
      } else {
        return (
          <Link to={url} className={className}>
            {iconElement}
            {children}
          </Link>
        );
      }
    }

    return (
      <button className={className} onClick={onClick} {...etc}>
        {iconElement}
        {children}
      </button>
    );
  }
}
