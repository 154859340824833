import BasicButton from "components/atoms/basicbutton/index.jsx";
import { currency } from "helpers/format";
import { Link } from "react-router-dom";
import "./PlanCard.scss";

function PlanCard({ plan }) {
  return (
    <div className={"plan-card"}>
      <div className="plan-card__image">
        <img src={plan.family?.promotion_image} alt={plan.name} />
      </div>
      <div className="plan-card__content">
        <div className="plan-card__name">{plan.name}</div>
        <div className="plan-card__installments">
          Cuota inicial * {currency(plan.first_installment)}{" "}
        </div>
        <div className="plan-card__months">{plan.installments} meses</div>
        <div className="plan-card__transctions">
          Operatoria {plan.integration}{" "}
        </div>

        <div className="plan-card__actions">
          <Link to={plan.book_url}>
            <BasicButton main inverted>
              Suscribirse
            </BasicButton>
          </Link>
        </div>
      </div>
      <div className="plan-card__info">
        <h4>{plan.name}</h4>
        {plan.vehicle_features.join(" / ")}
      </div>
    </div>
  );
}

export default PlanCard;
