import { url } from "helpers/format";

export default class SavingsPlan {
  constructor({ priority, family_id, ...props }, families) {
    for (let key in props) {
      if (props.hasOwnProperty(key)) {
        this[key] = props[key];
      }
    }

    this.family_id = parseInt(family_id, 10);
    this.priority = parseInt(priority, 10);

    this.setFamily(families);
  }

  setFamily(families = []) {
    this.family = families.find(
      (family) => family.name.toLowerCase() === this.family_name.toLowerCase()
    );
  }

  getCouponUrl(code) {
    return `/plan-chevrolet/subscripcion/${this.id}/${this.url_name}/${code}`;
  }

  get first_and_second_installment() {
    return this.first_installment + this.second_installment;
  }

  get url_name() {
    return url(this.name);
  }

  get book_url() {
    return `/plan-chevrolet/subscripcion/${this.id}/${this.url_name}`;
  }
}
