import { currency } from "helpers/format";

function PriceTable({ children, vehicle, coupon }) {
  return (
    <table className="table buy-table">
      <thead>
        <tr>
          {vehicle.special_discount > 0 ? (
            <th>Precio final bonificado</th>
          ) : (
            <th>Precio final</th>
          )}
          <th className="price">{currency(vehicle.getPrice(coupon))}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Precio de lista:</td>
          <td className="price">{currency(vehicle.list_price)}</td>
        </tr>
        <tr>
          <td>Entrega:</td>
          <td className="price">{currency(vehicle.delivery_price)}</td>
        </tr>
        <tr>
          <td>Patentamiento:</td>
          <td className="price">{currency(vehicle.license_price)}</td>
        </tr>
        {vehicle.special_discount > 0 && (
          <tr>
            <td>Descuento especial:</td>
            <td className="price">-{currency(vehicle.special_discount)}</td>
          </tr>
        )}
        {!vehicle.special_discount && vehicle.discount > 0 && (
          <tr>
            <td>Descuento web:</td>
            <td className="price">-{currency(vehicle.discount)}</td>
          </tr>
        )}
        {coupon && (
          <tr>
            <td>Código de descuento ${coupon.code}:</td>
            <td className="price">
              -{currency(vehicle.getDiscountFromCoupon(coupon))}
            </td>
          </tr>
        )}
        {children}
      </tbody>
    </table>
  );
}

export default PriceTable;
