import axios from "axios";
import Modal from "components/organisms/modal/index.jsx";
import FormRow from "components/molecules/forms/formrow/index.jsx";
import Textfield from "components/molecules/forms/textfield/index.jsx";
import BasicButton from "components/atoms/basicbutton/index.jsx";
import { useState } from "react";

function PriceRequest({ onClose, family, onSuccess }) {
  const [data, setData] = useState({
    name: "",
    lastname: "",
    email: "",
    telephone: "",
    vehicle: family.name,
  });
  const [sending, setSending] = useState(false);

  function handleChange(e) {
    setData({ ...data, [e.currentTarget.name]: e.currentTarget.value });
  }

  async function handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    setSending(true);

    try {
      await axios.post("/api/gm/request-price", { data });
    } catch (e) {
      console.error(e);
    }

    setSending(false);

    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <Modal
      title="Completa el formulario para ver nuestros precios y stock"
      open
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} id="price-form">
        <FormRow>
          <div className="grid">
            <div className="col col10">
              <Textfield
                label="Nombre"
                name="name"
                value={data.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col col10 s12 s-m-1">
              <Textfield
                label="Apellido"
                name="lastname"
                value={data.lastname}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className="grid">
            <div className="col col18">
              <Textfield
                label="E-mail"
                type="email"
                name="email"
                value={data.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className="grid">
            <div className="col col18 ">
              <Textfield
                label="Teléfono (opcional)"
                placeholder="Por ejemplo: 261 3 123123"
                type="phone"
                name="telephone"
                value={data.telephone}
                onChange={handleChange}
              />
            </div>
          </div>
        </FormRow>

        <div className="grid">
          <div className="col col24">
            <br />
            {!sending && (
              <BasicButton
                main
                onClick={() => handleSubmit()}
                disabled={!data.name || !data.lastname || !data.email}
              >
                Ver Precios
              </BasicButton>
            )}

            {sending && <>Un segundo...</>}
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default PriceRequest;
