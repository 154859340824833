import axios from "axios";
import TopBar from "../organisms/topbar/index.jsx";
import Footer from "../molecules/footer/index.jsx";
import BlockButton from "../atoms/blockbutton/index.jsx";
import BasicButton from "components/atoms/basicbutton/index.jsx";
import FormRow from "components/molecules/forms/formrow/index.jsx";
import Textfield from "components/molecules/forms/textfield/index.jsx";
import Select from "components/molecules/forms/select/index.jsx";
import Checkbox from "components/molecules/forms/checkbox/index.jsx";
import LinkingPos from "components/organisms/buy/LinkingPos";
import BenefitsTable from "components/organisms/buy/BenefitsTable";
import { useSavingsPlan } from "components/context/Context";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { scrollTo } from "helpers/scroll";
import { currency } from "helpers/format";
import { Helmet } from "react-helmet";

let couponTimeout;
function Subscribe() {
  const params = useParams();
  const plan = useSavingsPlan(params.id);
  const family = plan?.family;
  const [request, setRequest] = useState({
    name: "",
    lastname: "",
    dni: "",
    birthdate: "",
    email: "",
    telephone: "",
    address: "",
    marital_status_id: "",
    accept_terms: false,
  });
  const [code, setCode] = useState(params.coupon || "");
  const [coupon, setCoupon] = useState(undefined);
  const [transaction, setTransaction] = useState(undefined);
  const [requesting, isRequesting] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(plan?.first_installment);
  const [payedInstallments, setPayedInstallments] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (code) {
      handleCouponChange({ currentTarget: { value: code } });
    }
  }, [code]);

  function handleChange(e) {
    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value });
  }

  function handleCheckboxChange(e) {
    setRequest({
      ...request,
      [e.currentTarget.name]: !request[e.currentTarget.name],
    });
  }

  function handleCouponChange(e) {
    const code = e.currentTarget.value;
    setCode(code);
    setCoupon(undefined);

    function checkCoupon(code) {
      axios
        .post("/api/gm/plans/validate", { code })
        .then((response) => setCoupon(response.data))
        .catch((e) => {
          console.error(e);
          setCoupon(null);
        });
    }

    clearTimeout(couponTimeout);

    couponTimeout = setTimeout(() => checkCoupon(code), 500);
  }

  function handleSubmit(e) {
    e.preventDefault();

    isRequesting(true);
    setTransaction(undefined);

    let payedInstallments = 0;

    if (paymentAmount >= plan.first_and_second_installment) {
      payedInstallments = 2;
    } else if (paymentAmount >= plan.first_installment) {
      payedInstallments = 1;
    }

    const data = {
      ...request,
      code: coupon ? code : "",
      plan_first_installment: plan.first_installment,
      plan_first_and_second_installment: plan.first_and_second_installment,
      vehicle: plan.model,
      plan_id: plan.id,
      plan_name: plan.name,
      payed_installments: payedInstallments,
      payment_amount: paymentAmount,
    };

    axios
      .post("/api/gm/subscribe", { data })
      .then((response) => {
        isRequesting(false);
        setTransaction(response.data.transaction || null);
      })
      .catch((e) => {
        console.error(e);
        isRequesting(false);
        setTransaction(null);
      });
  }

  function book() {
    scrollTo("overview");
  }

  function begin() {
    scrollTo("book-form");
  }

  function goToTop() {
    scrollTo("subscription-title");
  }

  if (!plan) {
    return NoLongerAvailable();
  }

  const missingData =
    !request.name ||
    !request.lastname ||
    !request.dni ||
    !request.birthdate ||
    !request.email ||
    !request.marital_status_id ||
    !request.accept_terms ||
    !request.telephone;

  return (
    <>
      <Helmet>
        <title>Subscribirse a plan {plan.name} - Yacopini Motors</title>
        <meta
          name="description"
          content={`Subscripción plan de ahorro ${plan.name} - Plan Chevrolet - Yacopini Motors`}
        />
      </Helmet>

      <TopBar />

      <div className="section section--big">
        <div className="grid">
          <div className="col col12 col24m">
            <h4>Subscripción a Plan Chevrolet:</h4>
            <h1>{plan.family_name}</h1>
            <p className="p p-b">
              <strong> Plan:</strong> {plan.name} <br />
              <strong> Cuota inicial: </strong>{" "}
              {currency(plan.first_installment)}
              <br />
              <strong> Meses: </strong> {plan.installments}
              <br />
              <strong>Operatoria:</strong> {plan.integration} <br />
            </p>

            <BasicButton main onClick={book}>
              Subscribirme a Plan Chevrolet
            </BasicButton>
          </div>
          <div className="col col12 col24m">
            <img src={family?.detail_image} alt={family?.name} />
          </div>
        </div>
      </div>
      <div className="section section--gray">
        <div className="grid">
          <div className="col col8">
            <BlockButton title="1. DETALLES DEL PLAN">
              <p className="p">
                Consultá los detalles del plan y validá tu código de beneficios.
              </p>
            </BlockButton>
          </div>
          <div className="col col8">
            <BlockButton title="2. COMPLETA TUS DATOS">
              <p className="p">Completá tus datos personales.</p>
            </BlockButton>
          </div>
          <div className="col col8">
            <BlockButton title="3. PAGÁ TU SUBSCRIPCIÓN">
              <p className="p">
                Pagá hasta las primeras dos cuotas de tu Plan Chevrolet con tu
                tarjeta de débito, o crédito en 1, 2 o 3 cuotas.
              </p>
            </BlockButton>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="section section--big" id="overview">
        <div className="grid">
          <div className="col col10 s8 col-s-14 s-s-6 s-m-1">
            <h2 id="subscription-title">Subscripción a Plan Chevrolet</h2>

            {transaction === undefined && (
              <>
                <BenefitsTable plan={plan} coupon={coupon}>
                  {!coupon && (
                    <tr>
                      <td>
                        ¿Tenés un código de de beneficios? <br />
                        Ingresa tu código aquí.
                      </td>
                      <td className="price">
                        <Textfield
                          placeholder="XXXXXX-XX-XX-XX"
                          value={code}
                          onChange={handleCouponChange}
                          error={coupon === null}
                          errorMessage={
                            coupon === null
                              ? "Cupón inexistente o vencido"
                              : undefined
                          }
                        />
                      </td>
                    </tr>
                  )}
                </BenefitsTable>

                <br />
                <br />
                <BasicButton onClick={begin}>Comenzar</BasicButton>
              </>
            )}
          </div>
        </div>
      </div>

      {!transaction && (
        <form onSubmit={handleSubmit} id="book-form">
          <div className="section section--big">
            <div className="grid">
              <div className="col col10 s8 col-s-14 s-s-6 s-m-1">
                <h2>Completá tus datos personales</h2>
                <br />
                <FormRow>
                  <div className="grid">
                    <div className="col col12 col24m">
                      <Textfield
                        label="Nombre:"
                        name="name"
                        value={request.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col col12 col24m">
                      <Textfield
                        label="Apellido:"
                        name="lastname"
                        value={request.lastname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col10">
                      <Textfield
                        label="DNI:"
                        name="dni"
                        value={request.dni}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col col14">
                      <Textfield
                        label="Fecha de nacimiento:"
                        placeholder="DD/MM/AAAA"
                        type="date"
                        name="birthdate"
                        value={request.birthdate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col10">
                      <Select
                        label="Estado civil"
                        name="marital_status_id"
                        value={request.marital_status_id}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Seleccionar estado civil</option>
                        <option value="2">Casado</option>
                        <option value="3">Divorciado</option>
                        <option value="1">Soltero</option>
                        <option value="4">Viudo</option>
                      </Select>
                    </div>
                  </div>
                </FormRow>
                <hr />
                <FormRow>
                  <div className="grid">
                    <div className="col col20">
                      <Textfield
                        label="Dirección:"
                        name="address"
                        value={request.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col12 col24m">
                      <Textfield
                        label="Email:"
                        type="email"
                        name="email"
                        value={request.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="grid">
                    <div className="col col12 col24m">
                      <Textfield
                        label="Teléfono:"
                        name="telephone"
                        value={request.telephone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </FormRow>
              </div>
            </div>
          </div>

          <div className="section section--big">
            <div className="grid">
              <div className="col col10 s8 col-s-14 s-s-6 s-m-1">
                <h2>Confirma tu subscripción</h2>
                <p>
                  Estás a punto de subscribirte al plan {plan.name}
                  0KM.
                </p>
                <br />

                <BenefitsTable plan={plan} coupon={coupon} />

                <br />

                <FormRow>
                  <div className="grid">
                    <div className="col col20">
                      <Select
                        label="Cuotas a pagar"
                        value={payedInstallments}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          setPayedInstallments(value);
                          if (value === "1") {
                            setPaymentAmount(plan.first_installment);
                          } else if (value === "2") {
                            setPaymentAmount(plan.first_and_second_installment);
                          }
                        }}
                        required
                      >
                        <option value="0">Otro importe</option>
                        <option value="1">
                          Primera cuota {currency(plan.first_installment)}
                        </option>
                        <option value="2">
                          Primera y segunda cuota{" "}
                          {currency(plan.first_and_second_installment)}
                        </option>
                      </Select>
                    </div>
                  </div>
                </FormRow>
                {payedInstallments === "0" && (
                  <FormRow>
                    <div className="grid">
                      <div className="col col20">
                        <Textfield
                          label="Importe a pagar:"
                          type="number"
                          value={paymentAmount}
                          onChange={(e) =>
                            setPaymentAmount(e.currentTarget.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </FormRow>
                )}

                <br />
                <FormRow>
                  <div className="grid">
                    <div className="col col20">
                      <Checkbox
                        name="accept_terms"
                        value={true}
                        onChange={handleCheckboxChange}
                        checked={request.accept_terms}
                        text="Acepto los términos y condiciones"
                      />
                    </div>
                  </div>
                </FormRow>
                <br />
                <br />

                {!requesting && (
                  <BasicButton
                    main
                    type="submit"
                    disabled={missingData}
                    onClick={() => goToTop()}
                  >
                    Confirmar subscripción
                  </BasicButton>
                )}
                {requesting && <div>Un segundo...</div>}
                {transaction === null && (
                  <div>
                    <br />
                    <p>
                      <strong>No pudimos completar tu solicitud.</strong>
                    </p>
                    <p>
                      Por favor intenta nuevamente o{" "}
                      <a href="/contacto">contactanos</a>.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      )}

      {transaction && (
        <div className="section section--big">
          <div className="grid">
            <div className="col col10 s8 col-s-14 s-s-6">
              <h2>Pagá tu subscripción</h2>
              <LinkingPos amount={paymentAmount} transaction={transaction} />
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

function NoLongerAvailable() {
  return (
    <>
      <TopBar />

      <div className="section section--big">
        <div className="grid">
          <div className="col col12">
            <h4>Este vehículo ya no se encuentra disponible.</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscribe;
