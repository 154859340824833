import Slider from "components/molecules/slider";

function FamilyHero({ family }) {
  let sliderItems = [
    {
      image: family.promotion_image,
    },
  ];

  return <Slider title={family.name} items={sliderItems} />;
}

export default FamilyHero;
