import Slider from "components/molecules/slider";
import { useParameter } from "components/context/Context";

function HerosSlider({ title }) {
  const heros = useParameter("heros");

  let sliderItems = [];

  if (heros) {
    sliderItems = heros.map((hero) => ({
      image: hero.images[0]?.path,
      text: hero.title,
      url: hero.url,
    }));
  }

  return sliderItems.length > 0 ? (
    <Slider title={title} items={sliderItems} />
  ) : null;
}

export default HerosSlider;
