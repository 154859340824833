import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "images/chevrolet-logo.png";
import MenuButton from "components/atoms/menubutton/index.jsx";
import CarButton from "components/atoms/carbutton/index.jsx";
import { useFamilies } from "components/context/Context";
import "./TopBar.scss";

function TopBar() {
  const families = useFamilies();
  const [state, setState] = useState({
    metaState: "1",
    menuOpen: false,
    scrolled: false,
  });

  const toggleMenu = () => {
    setState({ ...state, menuOpen: !state.menuOpen });
  };

  useEffect(() => {
    function listenToScroll() {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const winSize = window.innerHeight - 64;

      setState((state) => {
        return {
          ...state,
          scrolled: winScroll > 0,
          metaState: winScroll > winSize ? "1" : "2",
        };
      });
    }

    window.addEventListener("scroll", listenToScroll);

    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  let className =
    "top-bar" +
    (state.menuOpen ? " top-bar-state-open" : "") +
    (state.scrolled ? " top-bar--scrolled" : "");

  return (
    <>
      <div className={className}>
        <div className="top-bar__main-menu">
          <div className="main-menu__logo">
            <Link to="/">
              <img src={Logo} alt="Yacopini Motors" />
            </Link>
          </div>
          <div className="main-menu__menu">
            <span onClick={toggleMenu}>
              <MenuButton arrow>Vehículos</MenuButton>
            </span>
            <MenuButton url="/oportunidades">Oportunidades</MenuButton>
            <MenuButton url="/postventa">Postventa</MenuButton>
            <MenuButton url="/plan-chevrolet">Planes de ahorro</MenuButton>
            <MenuButton url="/accesorios">Accesorios</MenuButton>
            {/*<MenuButton url="/financiacion">Financiación</MenuButton>*/}
            <MenuButton
              external
              url="https://territorioyacopini.com.ar/usados/"
            >
              Usados
            </MenuButton>
          </div>
          <div className="main-menu__right-menu">
            <MenuButton url="/contacto">Contacto</MenuButton>
            <MenuButton
              url="https://territorioyacopini.com.ar/solicitar-turno/chevrolet"
              external
            >
              Turnos Taller
            </MenuButton>
          </div>
        </div>
        <div className="top-bar__sections">
          <div className="section">
            <div className="grid">
              {families.map((family) => (
                <div
                  className="col col6"
                  key={family.id}
                  onClick={() => setState({ ...state, menuOpen: false })}
                >
                  <CarButton vehicle={family} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="top-bar__fixed-bars"></div>
      </div>
    </>
  );
}

export default TopBar;
