import { Component } from "react";
import "./Textfield.scss";

export default class Textfield extends Component {
  render() {
    const { label, type, placeholder, error, errorMessage, ...etc } =
      this.props;
    let className = "textfield" + (error ? " textfield--error" : "");

    let hasError = (etc.required && !etc.value) || error;

    return (
      <div className={className}>
        <label>{label}</label>
        <input type={type || "text"} placeholder={placeholder} {...etc} />
        {(hasError || errorMessage) && (
          <div className="textfield__error-message">
            {errorMessage || "Campo obligatorio"}
          </div>
        )}
      </div>
    );
  }
}
