import Footer from "../molecules/footer/index.jsx";
import Modal from "../organisms/modal/index.jsx";
import PlanCard from "../molecules/planCard/index.jsx";
import PlanCouponValidator from "components/organisms/plans/CouponValidator";
import Slider from "../molecules/slider/Heros.jsx";
import SliderImage1 from "images/generic-1.jpg";
import TopBar from "../organisms/topbar/index.jsx";
import { useSavingPlans } from "components/context/Context";
import { Helmet } from "react-helmet";

function Plans() {
  const sliderItems = [SliderImage1];
  const plans = useSavingPlans();

  return (
    <>
      <Helmet>
        <title>Plan Chevrolet - Yacopini Motors</title>
        <meta
          name="description"
          content={`Planes de ahorro - Plan Chevrolet - Yacopini Motors`}
        />
      </Helmet>

      <Modal>Promotions Units Modal</Modal>
      <TopBar />
      <Slider title="Planes de ahorro" items={sliderItems} />
      <div className="section section--big">
        <div className="grid">
          <div className="col col8">
            <h2>¿Qué es plan Chevrolet?</h2>
            <p>
              Es un sistema de ahorro en pesos y sin interés, en el cual un
              grupo cerrado de personas hace aportes mensuales, para la
              adquisición de su Chevrolet 0KM.
            </p>
          </div>
          <div className="col col8 s9 s-m-1">
            <h2>Acceder es simple y seguro.</h2>
            <p>
              Conoce todos nuestros planes y financia hasta el 100% en hasta 120
              cuotas. Basta con completar la solicitud de adhesión, sin
              requisitos ni anticipos.
            </p>
          </div>
          <div className="col col8 s17 s-m-1">
            <PlanCouponValidator />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="grid">
          {plans.map((plan) => (
            <div className="col col6 col-s-8" key={plan.id}>
              <PlanCard plan={plan} />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Plans;
