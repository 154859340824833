import BasicButton from "components/atoms/basicbutton/index.jsx";
import VehicleColor from "components/organisms/VehicleColor";
import { currency } from "helpers/format";
import { Link } from "react-router-dom";
import "./UnitCard.scss";

function UnitCard({ vehicle }) {
  return (
    <div className="unit-card">
      <div className="unit-card__info">
        <div className="unit-card__title">{vehicle.name}</div>
        <div className="unit-card__price">{currency(vehicle.getPrice())}</div>
        <div className="unit-card__details">
          {vehicle.special_discount > 0 && (
            <>
              <strong>Descuento especial: </strong>{" "}
              {currency(vehicle.special_discount)} <br />
            </>
          )}
          <strong>Color: </strong> {vehicle.color}{" "}
          <VehicleColor color={vehicle.hex_color} />
          <br />
          {vehicle.doors && (
            <>
              <strong>Puertas: </strong> {vehicle.doors} <br />
            </>
          )}
        </div>
      </div>
      <div className="unit-card__actions">
        <Link to={vehicle.book_url}>
          <BasicButton main small>
            {" "}
            Reservar
          </BasicButton>
        </Link>
        {/*<Link to={vehicle.finance_url}>
          <BasicButton small>Financiar</BasicButton>
        </Link>*/}
      </div>
    </div>
  );
}

export default UnitCard;
