import { url } from "helpers/format";

export default class Subfamily {
  constructor({ discount, features, ...props }) {
    for (let key in props) {
      if (props.hasOwnProperty(key)) {
        this[key] = props[key];
      }
    }

    this.discount = parseInt(discount, 10);
    this.features = features.sort();
  }

  get url_name() {
    return url(this.name);
  }
}
