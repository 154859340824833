import {
  getImage,
  getDetailImage,
  getVersionsImage,
  getWarrantyImage,
  getPromotionImage,
} from "helpers/image";
import { url } from "helpers/format";

export default class Family {
  offers = [];

  constructor({ id, name, description, discount, warranty }) {
    this.id = parseInt(id, 10);
    this.name = name;
    this.description = description;
    this.discount = discount;
    this.warranty = warranty;
  }

  get image() {
    return getImage(this);
  }

  get detail_image() {
    return getDetailImage(this);
  }

  get versions_image() {
    return getVersionsImage(this);
  }

  get warranty_image() {
    return getWarrantyImage(this);
  }

  get promotion_image() {
    return getPromotionImage(this);
  }

  get opportunities_url() {
    return `/oportunidades/${this.url_name}`;
  }

  get url() {
    return `/vehiculos-0km/${this.url_name}`;
  }

  get stock_url() {
    return `/vehiculos-0km/${this.url_name}/precios`;
  }

  get url_name() {
    return url(this.name);
  }
}
