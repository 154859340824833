import { Component } from "react";
import { Link } from "react-router-dom";
import "./BlockButton.scss";

export default class BlockButton extends Component {
  render() {
    const { children, image, url, title, small } = this.props;

    let className =
      "block-button" +
      (url ? " block-button--link" : "") +
      (small ? " block-button--small" : "");

    var imageElement;
    if (image) {
      imageElement = (
        <div className="block-button__image">
          <img src={image} alt={title} />
        </div>
      );
    }

    var buttonElement;
    if (url) {
      buttonElement = (
        <Link to={url} className={className}>
          {imageElement}
          {!small && <br />}
          <h3>{title}</h3>
          {children}
        </Link>
      );
    } else {
      buttonElement = (
        <div className={className}>
          {imageElement}
          {!small && <br />}
          <h3>{title}</h3>
          {children}
        </div>
      );
    }

    return <div>{buttonElement}</div>;
  }
}
