import "./Slider.scss";
import SliderArrow from "images/slick-arrow.png";
import CircleArrow from "images/circleArrow.svg";
import { useState } from "react";

function Slider({ items, noLink = false, title, noControls = false }) {
  const [active, setActive] = useState(0);

  let className =
    "slider" +
    (noLink ? " slider--no-link" : "") +
    (noControls ? " slider--no-controls" : "");

  var titleElement;
  if (title) {
    titleElement = (
      <div className="slider__title">
        <div className="grid">
          <div className="col col24">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    );
  }

  const buttons = [];
  for (let i = 0; items.length > 1 && i < items.length; i++) {
    buttons.push(
      <div
        className={`slider-button ${
          i === active ? "slider-button--active" : ""
        }`}
        onClick={() => setActive(i)}
        key={i}
      />
    );
  }

  return (
    <div className={className}>
      {titleElement}
      <div className="slider__controls">
        <div className="controls__arrows">
          {items.length > 1 && (
            <>
              <div
                className="controls__arrow__left"
                onClick={() =>
                  setActive(active - 1 < 0 ? items.length - 1 : active - 1)
                }
              >
                <img src={SliderArrow} alt="" />
              </div>
              <div
                className="controls__arrow__right"
                onClick={() =>
                  setActive(active + 1 === items.length ? 0 : active + 1)
                }
              >
                <img src={SliderArrow} alt="" />
              </div>
            </>
          )}
        </div>
        <div className="controls__bottom-bar">
          <div className="slider-buttons">{buttons}</div>
          {typeof items[active] === "object" && items[active].url && (
            <div className="slider-link">
              <a href={items[active].url}>
                {items[active].text} <img src={CircleArrow} alt="" />
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="slider__images">
        <div className="slider__image">
          <img
            src={
              typeof items[active] === "object"
                ? items[active].image
                : items[active]
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Slider;
